import React, { useEffect, useState } from "react";
import Header from "../Component/Common/Header";
import { news } from "../Data/News";
import DOMPurify from "dompurify";

function NewsDetail() {
  let url = new URLSearchParams(window.location.search).get("id");
  const [currentNews, setCurrentNews] = useState({});

  useEffect(() => {
    let newsItem = news.find((state) => state._id == url);
    if (newsItem) {
      let addcontent = document.getElementById("content");
      console.log(newsItem);
      addcontent.innerHTML = newsItem.content;
      setCurrentNews(newsItem);
    }
  }, [url]);

  return (
    <div className="bg-black min-h-screen">
      <Header />
      <div className="flex items-start justify-center max-lg:px-8">
        <div className="w-2/3 pt-14 text-white/80 max-lg:w-full ">
          <div className="text-white text-4xl"> {currentNews?.heading}</div>
          <img
            className="w-full h-[460px] object-cover object-top rounded-3xl my-5 max-md:h-[250px]"
            src={currentNews?.image}
            alt="fashion_model"
          />
          <div id="content" onLoad={(e) => console.log(e.target)}></div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
