import React from "react";
import Logo from "../../assets/Surshark_Logo_White_720px.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="w-full px-12 max-lg:px-6">
      <div className="w-full py-8    px-4 flex items-center justify-between rounded-b-2xl">
        <div>
          <Link to="/">
            <img src={Logo} alt="" className="w-44" />
          </Link>
        </div>
        <div className=" py-2 px-5 rounded-full text-white text-base font-semibold">
          {/* subscribe */}
        </div>
      </div>
    </div>
  );
}

export default Header;
