import React from "react";
import Header from "../Component/Common/Header";
import { news } from "../Data/News";
import { Link } from "react-router-dom";

function Home() {
  function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  return (
    <>
      <Header />
      <div className="w-full snap-y snap-start">
        <div className="w-full flex items-start h-[550px] px-20 max-lg:px-8">
          <div className="w-1/2 flex items-center justify-center h-full max-lg:w-full">
            <div className="h-full w-full flex-col flex items-start justify-center ">
              <div className="text-4xl text-white max-[520px]:text-3xl">
                {news[0].heading}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: news[0].content.slice(0, 350) + "...",
                }}
                className="text-base text-white/50 my-4"
              ></div>
              <Link to={"/newsdetail?id=" + news[0]._id}>
                <div className="bg-white text-black rounded-full py-3 px-6 font-bold mt-4">
                  Read more {">>"}
                </div>
              </Link>
            </div>
          </div>
          <div className="w-1/2 h-full relative pl-4 max-lg:hidden">
            {" "}
            <img
              className="w-full h-full object-cover object-top"
              src={news[0].image}
              alt="fashion_model"
            />
          </div>
        </div>
      </div>
      <div className="w-full px-20 py-10 max-lg:px-8">
        <div className="text-white py-4 text-3xl">Blog</div>
        <div className="w-full min-h-[450px] flex items-start max-md:flex-col">
          <div className="w-1/2  pr-3 max-md:w-full max-lg:pr-0 max-md:mb-5">
            {news.slice(1, 4).map((news) => {
              return (
                <div
                  className="w-full h-[550px] mb-5 max-md:h-[350px]"
                  onClick={() => {
                    window.location.assign(`/newsdetail?id=${news._id}`);
                  }}
                >
                  <img
                    className="w-full h-2/3 max-sm:h-[200px] object-cover object-top rounded-3xl"
                    src={news?.image}
                    alt="fashion_model"
                  />
                  <div className="text-2xl text-white mt-3 max-md:text-base">
                    {news?.heading}
                  </div>
                  <div className="text-base text-white/50 mt-2 ">
                    {stripHtml(news?.content)?.slice(0, 100) + "..."}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-1/2 h-full pl-3  max-md:w-full max-lg:pl-0 max-md:mb-5">
            {news.slice(4, 7).map((news) => {
              return (
                <div
                  className="w-full h-[550px] mb-5 max-md:h-[350px]"
                  onClick={() => {
                    window.location.assign(`/newsdetail?id=${news._id}`);
                  }}
                >
                  <img
                    className="w-full h-2/3 max-sm:h-[200px] object-cover object-top rounded-3xl"
                    src={news?.image}
                    alt="fashion_model"
                  />
                  <div className="text-2xl text-white mt-3 max-md:text-base">
                    {news?.heading}
                  </div>
                  <div className="text-base text-white/50 mt-2 ">
                    {stripHtml(news?.content)?.slice(0, 100) + "..."}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
